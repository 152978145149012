.activeexecutiontype {
    background-color: transparent;
    padding: 10px 10px 15px 10px;
    border-bottom: solid 2px #fff;
}

.inactiveexecutiontype {
    background-color: transparent;
    padding: 10px 10px 15px 10px;
    border-bottom: solid 2px rgba(255, 255, 255, 0.3);
}